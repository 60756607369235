import React from "react";

class HorizontalLine extends React.Component {
  render() {
    const divStyle = {
      margin: "4px 0",
      border: "2px solid",
      borderColor: this.props.item.color,
      width: "100%",
      height: "0",
    };

    return (
      <>
        <div className="container_component">
          <div style={divStyle}></div>
        </div>
      </>
    );
  }
}

export default HorizontalLine;
