import React from 'react';
import { Redirect } from "react-router-dom";
import { getDisplayOfDevice, loadDisplay } from "../api/Api";
import { delay } from "../utils/Utils"

class RedirectToDisplay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: "",
            displayID: 0,
            hwid: ""
        }
    }

    async componentDidMount() {
        
        this.reloadTimer = setInterval(this.reloadPage.bind(this), 300000);

        let baseURL = window.location.href

        if (baseURL.includes("foodgroup/")) {
            baseURL = baseURL.replace("foodgroup/", "")
            console.log(baseURL)
            window.location.href = baseURL
        }

        else if (baseURL.includes("edito/")) {
            baseURL = baseURL.replace("edito/", "")
            console.log(baseURL)
            window.location.href = baseURL
        }

        else if (baseURL.includes("fluidity/")) {
            baseURL = baseURL.replace("fluidity/", "")
            console.log(baseURL)
            window.location.href = baseURL
        }

        else if (baseURL.includes("restaurant/")) {
            baseURL = baseURL.replace("restaurant/", "")
            console.log(baseURL)
            window.location.href = baseURL
        }

        const queryString = window.location.search;
        console.log(queryString)

        const urlParams = new URLSearchParams(queryString);

        this.setState({ displayID: urlParams.get('display_id') })
        this.setState({ hwid: urlParams.get('hwid') })

        await delay(2500);

        console.log(this.state.hwid)
        console.log(this.state.displayID)

        if (this.state.hwid !== null) {
            console.log("inside hwid")
            await getDisplayOfDevice(this.state.hwid)
                .then((res => {
                    console.log(res)
                    this.setState({ displayID: res.id })
                }))
        }

        await loadDisplay(this.state.displayID).then((res) => {
            if (res) {
                console.log(res[0].orientation)
                console.log("Ciao")
                if (res[0].orientation === "landscape") {
                    this.setState({ redirectTo: "landscape" })
                }
                else if (res[0].orientation === "portrait") {
                    this.setState({ redirectTo: "portrait" })
                }
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.reloadTimer)
    }

    reloadPage() {
        window.location.reload()
    }

    render() {
        const { redirectTo } = this.state

        if (redirectTo === "landscape") {
            return <Redirect to={{
                pathname: 'displaylandscape',
                state: { displayID: this.state.displayID }
            }}
            />
        }
        else if (redirectTo === "portrait") {
            return <Redirect to={{
                pathname: 'displayportrait',
                state: { displayID: this.state.displayID }
            }}
            />
        }
        return (
            <div>
                <h1>Loading Display...</h1>
            </div>
        )
    }
}

export default RedirectToDisplay;