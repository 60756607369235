import { apiLink, dashboardLink } from "../Global"
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  }
  let json = response.json()
  return json.then(Promise.reject.bind(Promise))
}

const buildUrl = (path) => {
  path = path.replace(/^\//, '')
  let separator = path.includes('?') ? '&' : '?'
  return `${apiLink}/${path}${separator}`
}

const buildHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

export const loadDisplay = async (id) => {
  const response = await fetch(apiLink + "loaddisplaytemplate/" + id, {
    method: "GET",
    credentials: "same-origin"
  })
  const res = await checkStatus(response)
  if (res.status === 200)
    return res.json()
  console.log('loadDisplay returned an error:', res)
  return Promise.reject(new Error('loadDisplay returned an error'))
}
export const getValueLive = (id) => {

  let date = new Date().toISOString().
  replace(/T/, ' ').      // replace T with a space
  replace(/\..+/, '')     // delete the dot and everything after
  //replace(/:[0-9]+/i, '')

  date = date.split(":", 2).join(":")
  console.log(date)

  return fetch(apiLink + "getvalueliveforeditor?id=" + id + "&date=" + date, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getRegionByID returned an error:", res)
      return Promise.reject(new Error('getValueLive returned an error'))
    })
}
export const getRegionByID = (id) => {
  return fetch(apiLink + "region/" + id, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getRegionByID returned an error:", res)
      return Promise.reject(new Error('getRegionByID returned an error'))
    })
}
export const getZonesByPlaceID = (placeID) => {
  return fetch(apiLink + "getzonesbyplaceid/" + placeID, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getZonesByPlaceID returned an error:", res)
      return Promise.reject(new Error('getZonesByPlaceID returned an error'))
    })
}
export const getSVGMap = (id) => {
  return fetch(apiLink + "getmapsvg/" + id, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getSVGMap returned an error:", res)
      return Promise.reject(new Error('getSVGMap returned an error'))
    })
}
export const fetchWeather = (place) => {
  return fetch("https://api.openweathermap.org/data/2.5/weather?q=" + place + "&units=metric&appid=a159238dbf15165d45593f77ce6bd54a&lang=fr", {
    method: "GET",
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getSVGMap returned an error:", res)
      return Promise.reject(new Error('getSVGMap returned an error'))
    })
}
export const getCity = (displayID) => {
  return fetch(apiLink + "getcity?id=" + displayID, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getSVGMap returned an error:", res)
      return Promise.reject(new Error('getSVGMap returned an error'))
    })
}

export const getPromotionsByTag = (displayID, tagName) => {
  const currentdate = new Date(); 
  const datetime = currentdate.getFullYear() + "-" + ((currentdate.getMonth()+1) < 10 ? "0" + (currentdate.getMonth()+1) : (currentdate.getMonth()+1)) + "-" + (currentdate.getDate() < 10 ? "0" + currentdate.getDate() : currentdate.getDate());
  return fetch(apiLink + "getpromotionsbytag?display_id=" + displayID + "&tag=" + tagName + "&date=" + datetime, {
    method: "GET",
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getPromotionsByTag returned an error:", res)
      return Promise.reject(new Error('getPromotionsByTag returned an error'))

    })
}
export const getZonesOfDisplay = (displayID) => {
  return fetch(apiLink + "getfluidityeditor?display_id=" + displayID, {
    method: "GET",
    credentials: "same-origin",
  })
    .then(checkStatus)
    .then((res) => {
      if (res.status === 200) return res.json();
      console.error("getZonesOfDisplay returned an error:", res);
      return Promise.reject(new Error("getZonesOfDisplay returned an error"));
    });
};

export const getZonesOfDisplayByRestaurant = (placeID) => {
  return fetch(apiLink + "getfluidityeditor?place_id=" + placeID, {
    method: "GET",
    credentials: "same-origin",
  })
    .then(checkStatus)
    .then((res) => {
      if (res.status === 200) return res.json();
      console.error("getZonesOfDisplay returned an error:", res);
      return Promise.reject(new Error("getZonesOfDisplay returned an error"));
    });
};/* 
export const getZonesOfDisplay = (displayID) => {

  return fetch(apiLink + "fluiditydisplay?display_id=" + displayID, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getZonesOfDisplay returned an error:", res)
      return Promise.reject(new Error('getZonesOfDisplay returned an error'))

    })
} */
export const getfooditemsbyfoodgroupID = (id) => {
try {
  return fetch(apiLink + "getfooditemsbyfoodgroupdisplay/" + id, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getfooditemsbyfoodgroupID returned an error:", res)
      return Promise.reject(new Error('getfooditemsbyfoodgroupID returned an error'))

    })
  } catch(err) {
    alert(err);
  }
}

export const getDeviceOfDisplay = (id) => {

  return fetch(apiLink + "getdeviceofdisplay/" + id, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(res => {
      if (res.status === 200) return res.json()
    })
}

export const getFoodGroupByID = (foodGroupID) => {

  return fetch(apiLink + "getfoodbroupbyiddisplay/" + foodGroupID, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('getFoodGroupByID returned an error:', res)
      return Promise.reject(new Error('getFoodGroupByID returned an error'))
    })
}

export const uploadDisplayImage = (file, id) => {
  const body = new window.FormData()
  body.append('file', file)
  body.append('id', id)

  const headers = buildHeaders()
  delete headers['Content-Type']
  const params = {
    method: 'post',
    credentials: 'same-origin',
    headers,
    body
  }

  return fetch(apiLink + "savedisplayimage", params)
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('Error uploading file:', res)
      return Promise.reject(new Error('Error uploading file'))
    })
}

export const checkDisplayUpdate = (id) => {
  return fetch(dashboardLink + "updatescreendisplay/" + id, {
    method: "POST",
    credentials: "same-origin",
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('checkDisplayUpdate returned an error:', res)
      return Promise.reject(new Error('checkDisplayUpdate returned an error'))
    })
}

export const getDisplayVersion = (id) => {
  return fetch(apiLink + "getdisplayversion/" + id, {
    method: "GET",
    credentials: "same-origin",
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('getDisplayVersion returned an error:', res)
      return Promise.reject(new Error('getDisplayVersion returned an error'))
    })
}

export const getDisplayOfDevice = (hwid) => {

  return fetch(apiLink + "getdisplayofdevice/" + hwid, {
    method: "GET",
    credentials: "same-origin"
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error("getDisplayOfDevice returned an error:", res)
      return Promise.reject(new Error('getDisplayOfDevice returned an error'))

    })
}