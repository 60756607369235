export function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(key)) || {};
    } catch (e) {
      console.log(e)
    }
  }
  return ls[key];
}

export function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      key,
      JSON.stringify({
        [key]: value
      })
    );
  }
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

