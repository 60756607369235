import React from "react";
import GridLayout from "react-grid-layout";
import { getFromLS } from "../utils/Utils";
import { saveToLS } from "../utils/Utils";
import WebSocket from "../middleware/ws";
import { getDisplayVersion, uploadDisplayImage } from "../api/Api";

import {
  Background,
  Bar,
  DateAndTime,
  Dispatch,
  FluidityLine,
  FluidityRectangle_Land,
  FoodGroup_Land,
  HorizontalLine,
  ImageComponent,
  InfoFluidity,
  Logo,
  MultiFoodGroup_Land,
  Promotion,
  PromoVertical,
  Saint,
  TitleBar,
  TitleText,
  VerticalLine,
  Weather,
} from "../components/displayComponents/index";
import { loadDisplay, getDeviceOfDisplay, checkDisplayUpdate } from "../api/Api";

const originalLayout = getFromLS("layout") || [];
const originalItems = getFromLS("items") || [];

class Display extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: [],
      items: [],
      device: {},
      loadingDevice: true,
      loadingDisplay: true,
      reloaded: false,
      hasDisconnected: false
    };

  }

  componentDidMount() {
    localStorage.removeItem("layout");
    localStorage.removeItem("items");

    getDeviceOfDisplay(this.props.location.state.displayID).then((res) => {
      if (res) {
        this.setState({ device: res });
      }
      this.setState({ loadingDevice: false });
    });

    console.log("property_id", this.props.location.state.displayID);
    loadDisplay(this.props.location.state.displayID).then((res) => {
      if (res) {
        this.setState({ layout: res[2].layout });
        this.setState({ items: res[1].items });
        //window.location.reload();
        console.log(this.state.layout);
        console.log(this.state.items);
        this.setState({ loadingDisplay: false });

      } else {
        console.log("ERROR LOADING DISPLAY");
      }

      this.setState({ reloaded: true });
      //window.location.reload();
    });

    setInterval(this.checkScreenUpdate.bind(this), 60000)
    setInterval(this.checkDisplayVersion.bind(this), 60000)
  }

  checkDisplayVersion() {
    getDisplayVersion(this.props.location.state.displayID)
    .then((res) => {
      if ((!localStorage.getItem("displayVersion")) || ((parseInt(localStorage.getItem("displayVersion"))) < res) || (this.state.hasDisconnected === true)) {
        localStorage.setItem("displayVersion", res)
        window.location.reload()
        this.setState({hasDisconnected: false})
      }
    },
    reject => {
      console.log(reject)
      this.setState({ hasDisconnected: true})
    })
  }

  buildDisplay = () => {
    console.log("build display");
    loadDisplay(this.props.location.state.displayID).then((res) => {
      this.setState({ loadingDisplay: true });
        // saveToLS("layout", res[2].layout);
        // saveToLS("items", res[1].items);

        this.setState({ layout: res[2].layout });
        this.setState({ items: res[1].items });

        this.setState({ loadingDisplay: false });
    },
    reject => {
      console.log(reject)
    }
    );
  };

  buildUrl = (id) => {
    var uri = "ws:";

    if (window.location.protocol === "https:") {
      uri = "wss:";
    }

    const url = new URL(window.location.href);

    if (process.env.REACT_APP_DEBUG === "true") {
      return `${uri}//imokb.localhost:81/api/ws?id=${id}`;
    }

    return `${uri}//${window.location.host}/api/ws?id=${id}`;
  };

  createElement(el) {
    switch (el.i.substring(0, 3)) {
      case "BAC":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Background
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );

      case "BAR":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Bar
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );

      case "PRO":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Promotion
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              currentDisplay={this.props.location.state.displayID}
              layout={this.state.layout}
              device={this.state.device}
            />
          </div>
        );
      case "PRV":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <PromoVertical
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              currentDisplay={this.props.location.state.displayID}
              layout={this.state.layout}
              device={this.state.device}
            />
          </div>
        );
      case "LOG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Logo
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "IMG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <ImageComponent
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "TIT":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <TitleBar
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              layout={this.state.layout}
            />
          </div>
        );
      case "MLH":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <HorizontalLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "MLV":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <VerticalLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "DAT":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <DateAndTime
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "SAN":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Saint
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "WEA":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Weather
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              currentDisplay={this.props.location.state.displayID}
            />
          </div>
        );
      case "FLL":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <FluidityLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "FLR":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            className="noresize"
          >
            <FluidityRectangle_Land
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onFluidityRectangleSizeChange={this.onFluidityRectangleSizeChange}
            />
          </div>
        );
      case "FIM":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <InfoFluidity
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "DSM":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            className="noresize"
          >
            <Dispatch
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onDispatchSizeChange={this.onDispatchSizeChange}
            />
          </div>
        );
      case "FGL":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
            className="noresize"
          >
            <FoodGroup_Land
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onFoodGroupLandSizeChange={this.onFoodGroupLandSizeChange}
            />
          </div>
        );
      case "TTX":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <TitleText
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "MFG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <MultiFoodGroup_Land
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
    }
  }

  checkScreenUpdate() {
    checkDisplayUpdate(this.state.device.hwid)
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    console.log("Query variable %s not found", variable);
  }

  render() {
    if (
      this.state.loadingDisplay === true ||
      this.state.loadingDevice === true
    ) {
      return <div></div>;
    } else {
      var that = this;
      var device = this.state.device;
      console.log({ device });
      const connectToWS = 1;
      return (
        <div>
          <WebSocket
            url={that.buildUrl(device.hwid)}
            reconnect={true}
            onMessage={function () {
              that.buildDisplay();
            }}
          />
          <GridLayout
            {...this.props}
            className="layout landscape"
            layout={this.state.layout}
            width={1920}
            rowHeight={10}
            maxRows={108}
            cols={192}
            verticalCompact={false}
            preventCollision={true}
            margin={[0, 0]}
            containerPadding={[0, 0]}
            autoSize
            isDraggable={false}
            isResizable={false}
          >
            {this.state.items.map((el) => this.createElement(el))}
          </GridLayout>
        </div>
      );
    }
  }
}

export default Display;
